<script>
import lottie from 'lottie-web';
import animationData from '@/assets/animations/home1.json';
import animationDataMobile from '@/assets/animations/homemobile.json';

export default {
  name: 'faq',

  mounted() {
    const params = {
      container: this.$refs.bg,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    };

    lottie.loadAnimation(params);

    params.container = this.$refs.bgmobile;
    params.animationData = animationDataMobile;

    lottie.loadAnimation(params);
  },
};

</script>

<template>
  <div class="privacyPolicy wrap">
    <the-nav>
      <router-link active-class="is-active" :to="{ name: 'home' }">
        Hire now
      </router-link>
    </the-nav>

    <header class="privacyPolicy_header">

      <h1 class="title is-2">Privacy Policy</h1>

      <div class="bg" ref="bg"></div>
      <div class="bgmobile" ref="bgmobile"></div>
    </header>

    <body>
      <div>
        <p>This Privacy Policy describes the types of information gathered by Heroes Jobs Inc., 2219 Main St., Santa Monica, CA 90405. ("Heroes Jobs", "us" or "we") in the process of providing this website, the Heroes Jobs platform, computing application, and the data, services, information, tools, functionality, updates and similar materials (collectively, the "Service"), how we use it, with whom the information may be shared, what choices are available to you regarding collection, use and distribution of information and our efforts to protect the information you provide to us through the Service.</p>
        <p>By using the Service, you hereby consent to allow us to process information in accordance with this Privacy Policy. Please also refer to our Terms of Service, which are incorporated as if fully recited herein. Terms defined in the Terms of Service that are not defined herein shall have the same definition as in the Terms of Service.</p>
        <p>This policy is subject to change. If our information retention or usage practices change, we will let you know by posting the Privacy Policy changes on the Service and/or otherwise making you aware of the changes. Your continued use of the Service following our notice of changes to this Privacy Policy means you accept such changes. Please refer to the “Effective Date” above to see when this Policy was last updated.</p>
      </div>

      <div>
        <h2>Territoriality</h2>
        <p>Regardless of where our servers are located, your personal data may be processed by us in the United States, where data protection and privacy regulations may or may not be to the same level of protection as in other parts of the world. BY VISITING THE WEBSITE AND USING THE SERVICE, YOU UNEQUIVOCALLY AND UNAMBIGUOUSLY CONSENT TO THE COLLECTION AND PROCESSING IN THE UNITED STATES OF ANY INFORMATION COLLECTED OR OBTAINED BY US THROUGH VOLUNTARY SUBMISSIONS, AND THAT U.S. LAW GOVERNS ANY SUCH COLLECTION AND PROCESSING.</p>
      </div>

      <div>
        <h2>Information Collected</h2>
        <p>We collect two types of information about persons who use the Service: voluntarily provided personal information and automatically collected information.</p>

        <div>
          <h3>Personal Information Collected</h3>
          <p>We collect certain Personal Information about you, which may be supplied when you sign-up for the Service, when you complete a survey, when you create or update an account, when you use the Service, when you request services or otherwise when you submit such information. The information that may be collected includes:</p>
          <ul>
            <li>Name;</li>
            <li>Phone number;</li>
            <li>Email;</li>
            <li>Social media account names and information;</li>
            <li>Employment information (only from Candidates);</li>
            <li>First party cookies;</li>
            <li>Third party cookies;</li>
            <li>Image, audio and video from screening interviews;</li>
            <li>Chat history and message read receipts,</li>
            <li>User profile information, including images;</li>
            <li>Location data; and</li>
            <li>Device identifiers.</li>
          </ul>
          <p>You may provide us information when you interact with us through email, online chat, messaging functions within the Service, or otherwise. We may retain such information in order to provide you with services, and you agree that we may share this information as needed with other users in order to resolve any issues that may arise between you and another user of the Service.</p>
          <p>The Service may record photos, audio or video if you permit such access in order to use certain features of the Service, such as to record a screening interview through the Service. The Service may also request permission and access to your photo gallery, camera roll or other device storage area holding your images, audio and/or videos, in order for you to upload and transmit them through the Service.</p>
          <p>The Service may access your device file storage if you permit such access in order to use certain features of the Service, such as to upload a résumé.</p>
          <p>The Service may access the internet, or use your mobile data plan in order to use certain features of the Service, such as communication with other users through the Service.</p>
          <p>The Service may access your device to send you push notifications.</p>
          <p>Although it may appear that the Service collects credit card information from Employers, it is collected and processed through a third-party provider. We do not hold your credit card information.</p>
          <p>The Service may be integrated with third-party social media platforms, such as Facebook and Twitter (“Platforms”). If you interact with the Platforms through the Service, the Platforms may make additional personal information about you available to us.</p>
        </div>

        <div>
          <h3>Non-Personal Information</h3>
          <p>Non-Personal Information is non-personally identifiable or anonymous information about you, including but not limited to links and materials posted, enrollment history, purchase history, the type of device you used and its operating system, the pages accessed most frequently, how pages are used, applications downloaded, search terms entered, and similar non-personal data. If you can be identified from this information, for example by combination with other pieces of information, then we will treat this information as Personal Information.</p>
          <p>Automatically tracking Internet Protocol (IP) addresses is one method of automatically collecting information about your activities online and information volunteered by you. An IP address is a number that is automatically assigned to your device whenever you surf the internet. Further, the Service may utilize web beacons, pixel tags, cookies, embedded links, and other commonly used information-gathering tools. The Service may also collect device identifiers.</p>
          <p>Although it may be possible to turn off the collection of cookies through your device, that may interfere with your use of the Service.</p>
          <p>Part of the purpose of the Service is to share materials you make available. Anything you publicly post will not be considered Personal Information.</p>
        </div>
      </div>

      <div>
        <h2>Use of Information</h2>
        <div>
          <h3>Internal Use of Information</h3>
          <p>We accept and gather information in an effort to provide the Service to you. We may also use this information to help us develop and improve our Service and other offerings, fulfill your requests, track usage trends, conduct experiments and perform research and analytics, communicate with you regarding administrative matters, send you push notifications from time-to-time, send materials to you, tailor our Service and offerings to meet your interests and those of others, and for other purposes permitted by law.</p>
          <p>We may use anonymous, aggregate information, of which your information may be a part, without restriction.</p>
        </div>

        <div>
          <h3>Sharing Information Collected with Third Parties, Consultants, and Affiliates</h3>
          <p>We may share information with our business associates, consultants, service providers, advisors and affiliates in order for them to provide services to us, to you, and to enable us to provide the Service. For example, our host and internet service provider may have access to this information. We may also share your information for marketing purposes.</p>
          <p>If you use the Service to interact with other users, your information may be shared with those other users and will also be accessible by us. For example, Employers requesting an interview with a Candidate will be provided certain personal information about such Candidate, including name, email address, phone number, social media information, other information associated with such Candidate’s profile, and information from video screening interviews – including profile data, images, video, audio, speech analytics, speech transcription, the results of our analysis of Biometric Information (as defined herein), and other data.</p>
          <p>We may share information we collect about you with third parties for marketing purposes, as well as share such information with others on the Service that you identify for such sharing.</p>
          <p>We may share information from video screening interviews – including profile data, images, video, audio, speech analytics, speech transcription, Biometric Information, the results of our analysis of Biometric Information, and other data – with third party electronic storage providers for record retention purposes.</p>
          <p>We publicly identify Users and their profile pages, and any information you, as a User, post to those pages will be made public.</p>
          <p>Further, we may disclose collected information to the extent we believe it necessary to comply with the law, such as in response to a subpoena or court order, to defend a legal claim or otherwise as permitted by applicable law. We may disclose any information in our possession in the event that we believe it necessary or appropriate to prevent criminal or illegal activity, personal injury, property damage or bodily harm.</p>
          <p>Additionally, we may transfer your information to successor in interest, which may include but may not be limited to a third-party in the event of an acquisition, sale, asset sale, merger or bankruptcy.</p>
        </div>
      </div>

      <div>
        <h2>Links</h2>
        <p>Our Service may contain links to other websites. We are not responsible for the privacy practices of such other sites. When you leave our Service to visit another website or application, please read the privacy statements of websites that may collect personally identifiable information. This Privacy Policy applies solely to information collected by us through the Service.</p>
      </div>

      <div>
        <h2>Security</h2>
        <p>We employ procedural and technical safeguards to secure your personal information against loss, theft, alteration, and unauthorized access, use and disclosure. We also employ security procedures to protect your information from unauthorized access by users inside and outside the company.</p>
        <p>Regardless of the precautions we take, no transmission of data over the internet is guaranteed to be completely secure. It may be possible for third parties not under our control to intercept or access transmissions or private communications unlawfully. While we strive to protect personal information, we cannot ensure or warrant the security of any information you transmit to us.</p>
      </div>

      <div>
        <h2>E-Mail and Electronic Newsletters</h2>
        <p>We may offer electronic newsletters and e-mails concerning promotions, new products and services, or other marketing materials as a service to our users. You may receive newsletters and e-mails concerning promotions and marketing of ours, after you register through with the Service. If, after you have received a message, you no longer wish to receive such materials, you may opt-out by following the unsubscribe instructions included in each electronic newsletter and e-mail.</p>
      </div>

      <div>
        <h2>Access and Control</h2>
        <p>User profile, contact and billing information will be editable through the Service, outlined in this Privacy Policy. Some other information collected about you may be retrievable and changeable through your username login. To do so, follow instructions on the Service.</p>
      </div>

      <div>
        <h2>Communication Tools</h2>
        <p>The Service, outlined in this Privacy Policy, may contain tools that enable you to share personal information, including sensitive information, with others, on the Service. Should you choose to use such tools, please exercise caution when sharing sensitive information.</p>
      </div>

      <div>
        <h2>Do Not Track</h2>
        <p>At this time, the Service, outlined in this Privacy Policy, does not specifically respond to do-not-track signals.</p>
      </div>

      <div>
        <h2>Children and Privacy</h2>
        <p>We do not knowingly permit users to register for our Service if they are under 16 years old, and therefore do not request personally identifiable information from anyone under the age of 13. If we become aware that a customer is under the age of 13 and has registered without prior verifiable parental consent, we will remove his or her personally identifiable registration information from our files. If you are the parent or guardian of a person under the age of 13 who has provided personally identifiable information to us without your approval, please inform us by contacting us at the e-mail address below and we will remove such information from our database.</p>
      </div>

      <div>
        <h2>California Privacy Rights</h2>
        <p>California law allows California residents to request information regarding our disclosures in the prior calendar year, if any, of their personally identifiable information to third parties. To make such a request, please contact us at hello@heroes.jobs with “Request for Privacy Information” in the subject line. Please include enough detail for us to locate your file; at a minimum, your name, email, and username, if any. We will attempt to provide you with the requested information within thirty (30) days of receipt. We reserve our right not to respond to requests sent more than once in a calendar year, or requests submitted to an address other than the one posted in this notice. Please note that this law does not cover all information sharing. Our disclosure only includes information covered by the law.</p>
      </div>

      <div>
        <h2>Contact Information</h2>
        <p>If you have any questions or suggestions regarding our Privacy Policy, please contact us via e-mail at hello@heroes.jobs</p>
      </div>

    </body>
  </div>
</template>


<style lang="scss" scoped>

.privacyPolicy {
  &_header {
    text-align: center;
    margin-top: 90px;
    margin-bottom: 90px;
  }

  p {
    margin-bottom: 20px;
  };

  h2 {
    font-weight: bold;
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 15px;
    text-transform: uppercase;
  };

  h3 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  };

   li{
    margin-bottom: 10px;
  };

  ul {
    list-style-type: square;
    padding-left: 30px;
    margin-bottom: 0;
  };
}

</style>
